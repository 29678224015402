export class SearchEventModel {
	term: string

	type: string

	resultCount: number

	constructor(term: string, type: string, resultCount: number) {
		this.term = term;
		this.type = type;
		this.resultCount = resultCount;
	}

	get pageId() {
		const { pathname = '' } = window.location;
		const normalizedPathname = pathname.replace(/^\/m/, '');

		if (normalizedPathname.length) {
			return normalizedPathname.replace(/\/(?!$)/g, ':').replace(/\/|(\..+$)/g, '').toLowerCase();
		}
		return 'homepage';
	}

	get trackingJson() {
		return {
			event: 'internalsearch',
			page: {
				id: this.pageId,
			},
			search: {
				term: this.term,
				type: this.type,
				result: this.resultCount,
			},
		};
	}
}
