export const crazyS7ImageEscape = (url) => {
	let result = url;

	// s7 accepts parens in the place of curly braces. who knew??
	// the Pinterest mobile app was barfing on the curly braces, so this fixed it
	result = result.replace((/\{/g), '(');
	result = result.replace((/\}/g), ')');
	result = result.replace((/\?/g), 'xquesx');
	result = result.replace(/&/g, 'xampx');
	result = result.replace(/=/g, 'xeqx');

	return result;
};
