'use client';

import { observer } from 'mobx-react';
import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

import { PageViewEventModel } from '~/tracking/pageview-event/PageViewEvent.tracking.model';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { VisitorEventModelFactory } from '~/tracking/visitor-event/Models/VisitorEvent.tracking.model';

// This component is used to call the pageViewEvent for tracking. It is an anaomaly from all other tracking because it needs to be called from the head script and is dependent on visitor information.
export const PageViewEvent = observer(({ prefixedTitle, type, errorStatusCode }: { prefixedTitle: string, type: string, errorStatusCode: any }) => {
	const {
		globalDynamicStore,
		globalDynamicStore: {
			hasInitiallyLoaded = false,
			model: {
				isLoggedIn = undefined,
			} = {},
		} = {},
		personalizationScope,
	} = useGlobalContext();

	const visitorEventModel = VisitorEventModelFactory.create(globalDynamicStore);

	const pageViewEventModel = new PageViewEventModel(prefixedTitle, type, errorStatusCode, personalizationScope, visitorEventModel);

	const didTrackPageViewRef = useRef(false);

	const pathname = usePathname();

	useEffect(() => {
		if (hasInitiallyLoaded && (!didTrackPageViewRef.current || isLoggedIn)) {
			pageViewEventModel.trackPageView();
			didTrackPageViewRef.current = true;
		}
	}, [hasInitiallyLoaded, isLoggedIn, pathname]);

	return null;
});
