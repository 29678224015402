import { isOnServer } from '~/global/global.constants';
import { TrackingDebugMode, TrackingDebugModeFactory } from '~/tracking/debug/Tracking.debugMode';
import { SearchEventModel } from '~/tracking/search/Models/SearchEvent.tracking.model';
import { FeatureTogglesModel } from '~/util/feature-toggles/Models/FeatureToggles.model';

export class SearchEventsTrackingStore {
	debugMode!: TrackingDebugMode;

	spyAttemptCounter: number;

	term: string | null;

	constructor(debugMode: TrackingDebugMode) {
		this.debugMode = debugMode;
		this.spyAttemptCounter = 0;
		this.term = null;
	}

	createSpy() {
		const tsWindow: any = window as any;
		setTimeout(() => {
			this.spyAttemptCounter++;
			if (tsWindow.rfk?.P?.fs?.sb?.p) {
				Object.defineProperty(tsWindow.rfk.P.fs.sb.p, 'response', {
					set: (x) => {
						const {
							orig_keyphrase: searchTerm,
							num_results: resultCount = 0,
						} = x;
						tsWindow.rfk.P.fs.sb.p.rnbResponseHolder = x;

						this.term = searchTerm;

						this.trackSearchEvent(searchTerm, 'preview search', resultCount);
					},
					get: () => {
						return tsWindow.rfk.P.fs.sb.p.rnbResponseHolder;
					},
				});
			} else if (this.spyAttemptCounter < 4) {
				this.createSpy();
			}
		}, 500);
	}

	trackSearchEvent(term: string, type: string, resultCount: number) {
		try {
			const event = new SearchEventModel(term, type, resultCount);
			this.pushToEventDataLayer(event.trackingJson);
		} catch (error) {
			console.error('Error tracking internal search', error);
		}
	}

	pushToEventDataLayer(json: any) {
		const tsWindow: any = window as any;
		if (!isOnServer && tsWindow?.eventDataLayer) {
			if (this.debugMode.shouldLogLinkEvents) {
				console.info(json);
			}
			tsWindow.eventDataLayer.push(json);
		}
	}
}

export const SearchEventsTrackingStoreFactory = {
	create: (featureTogglesModel: FeatureTogglesModel) => {
		const debugMode = TrackingDebugModeFactory.create(featureTogglesModel);
		const store = new SearchEventsTrackingStore(debugMode);
		store.createSpy();

		return store;
	},
};
